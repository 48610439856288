import styled from '@emotion/styled';
import { forwardRef, useImperativeHandle } from 'react';
import { CheckRounded, ClearRounded } from '@mui/icons-material/';
import { colors } from '../../styles/Colors';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Constants } from 'Constants';
export interface CardAnswerRef {}

interface TopRightIconProps {
    isCorrect: boolean;
}

export interface CardAnswerProps {
    card: {
        index: number;
        character: string;
        phrase: string;
        detectedCard1?: { serial: string; orientation: string } | {};
        detectedCard2?: { serial: string; orientation: string } | {};
    };
    isCorrect: boolean;
    locale: Constants.Locale;
}

export const CardAnswer = forwardRef((props: CardAnswerProps, ref) => {
    const { card, isCorrect } = props;
    const { t } = useTranslation();
    useImperativeHandle(ref, () => ({}));

    return (
        <Container>
            {card?.detectedCard1 && Object.keys(card?.detectedCard1).length > 0 && (
                <CharacterContainer>
                    <TopRightIconContainer>
                        {'serial' in card?.detectedCard1 ? (
                            <Box
                                component='img'
                                src={`/asset/card/${props.locale}/${card?.detectedCard1?.serial}.png`}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    ...(card?.detectedCard1?.orientation === 'Down' ? { transform: 'rotate(180deg)' } : {}),
                                }}
                            />
                        ) : (
                            <Typography
                                sx={{
                                    display: 'flex',
                                    marginRight: '44px',
                                    gap: '12px',
                                }}>
                                {t('resultPage.read-card-fail')}
                            </Typography>
                        )}
                        <TopRightIcon isCorrect={isCorrect}>
                            {isCorrect ? (
                                <CheckRounded
                                    sx={{
                                        color: colors.reallyWhite,
                                        display: 'block',
                                        margin: 'auto',
                                    }}
                                />
                            ) : (
                                <ClearRounded
                                    sx={{
                                        color: colors.reallyWhite,
                                        display: 'block',
                                        margin: 'auto',
                                    }}
                                />
                            )}
                        </TopRightIcon>
                    </TopRightIconContainer>
                </CharacterContainer>
            )}
            {card?.detectedCard2 && Object.keys(card?.detectedCard2).length > 0 && (
                <CharacterContainer>
                    <TopRightIconContainer>
                        {'serial' in card?.detectedCard2 ? (
                            <Box
                                component='img'
                                src={`/asset/card/${props.locale}/${card?.detectedCard2?.serial}.png`}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    ...(card?.detectedCard2?.orientation === 'Down' ? { transform: 'rotate(180deg)' } : {}),
                                }}
                            />
                        ) : (
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                    alignItems: 'center', // Center vertically
                                    color: 'red',
                                }}>
                                {t('resultPage.read-card-fail')}
                            </Typography>
                        )}
                        <TopRightIcon isCorrect={isCorrect}>
                            {isCorrect ? (
                                <CheckRounded
                                    sx={{
                                        color: colors.reallyWhite,
                                        display: 'block',
                                        margin: 'auto',
                                    }}
                                />
                            ) : (
                                <ClearRounded
                                    sx={{
                                        color: colors.reallyWhite,
                                        display: 'block',
                                        margin: 'auto',
                                    }}
                                />
                            )}
                        </TopRightIcon>
                    </TopRightIconContainer>
                </CharacterContainer>
            )}
        </Container>
    );
});

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
`;

const CharacterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 184px;
    height: 184px;

    background: #ffffff;

    border: 1px solid #856666;
    border-radius: 8px;
`;

const TopRightIconContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const TopRightIcon = styled.div<TopRightIconProps>`
    display: flex;
    position: absolute;
    width: 32px;
    height: 32px;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    background: ${(props) => (props.isCorrect ? colors.success400 : colors.error500)};
`;
