import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Button, Typography, makeStyles, Dialog } from '@mui/material';
import { Check, CheckRounded, Clear, ClearRounded, QuestionMark } from '@mui/icons-material';
import { WritingAnswer } from '../WritingAnswer';
import { colors } from '../../styles/Colors';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { CardAnswer } from '../CardAnswer';
import { GameType, gameTypeStr, GradingType } from 'pages/results/utils';
import { EasyWritingAnswer } from 'components/EasyWritingAnswer';
import { AudioAnswer } from 'components/AudioAnswer';
import { useAudioResultAnswer } from 'hooks/useAudioResultAnswer';
import { ResultsPageContext } from 'pages/results/models';
import { useNavigate, useOutletContext } from 'react-router-dom';
import MultipleChoiceAnswer, { MCQuestionProp } from 'pages/results/component/MultipleChoice/MultipleChoiceAnswer';
import HKcsQ2Textbubbles from 'pages/results/component/HkcsQ2/HKcsQ2Textbubbles';
import FeedbackIcon from 'pages/results/component/CorrectTopRightIcon/FeedbackIcon';
import { HkcsQ1QuestionImages, useGetHkcsImagesHook } from 'pages/results/hook/useGetHkcsImagesHook';
import HkcsQ3InGameLayout, { HkcsQuestionImages } from 'pages/results/component/HkcsQ3/HkcsQ3InGameLayout';
import { SelectClassPath, SelectTestroomPath, ClassOverallResultPath } from 'routes/utils';
import { FillInTheBlankQuestionComponent } from 'pages/lessons/components/dataComponents/props/components/FillInTheBlankQuestionComponent';
import HkSasFillInTheBlankAnswer from 'pages/results/component/HkSasFillInTheBlank/HkSasFillInTheBlankAnswer';
import SasRadicalMatchingAnswer, { SasCardAnswer } from 'pages/results/component/SasRadicalMatching/SasRadicalMatchingAnswer';
import { json } from 'stream/consumers';
import { EnglishWritingAnswer } from 'components/EnglishWritingAnswer/EnglishWritingAnswer';
import { Constants } from 'Constants';
export interface AnswerCardDataProps {
    index: number;
    question: string | undefined;
    questionID: string;
    questionType: GameType;
    correct: boolean;
    grading: boolean;
    data: any;
    locale?: Constants.Locale;
}
interface TopRightIconProps {
    isCorrect: boolean;
}
export interface AnswerCardProps {
    data: AnswerCardDataProps;
    onGrading(items: any[], grade: GradingType): void;
}

export const AnswerCard = (props: AnswerCardProps) => {
    const { data, onGrading } = props;
    const navigator = useNavigate();
    const locale = props.data.locale ?? 'zh-HK';
    const { t } = useTranslation();
    const { selectedTestRoom } = useOutletContext<ResultsPageContext>();
    const [selectedAnswer, setSelectedAnswer] = useState<any[]>([]);
    const [grade, setGrade] = useState('');
    const [mcQuestios, setMcQuestions] = useState<MCQuestionProp[]>([]);
    const { setHkcsImage, hkcsImage } = useGetHkcsImagesHook();

    const renderCardAnswers = (cards: AnswerCardDataProps) => {
        let answersGroups: any[] = [];

        // TTEACH-150
        // sorted by endTime
        const sortedCardData = cards.data.sort((a: any, b: any) => {
            return new Date(a.endTime).getTime() - new Date(b.endTime).getTime();
        });

        for (const card of sortedCardData) {
            const cardObj = JSON.parse(card?.data);
            if (answersGroups.length > 0) {
                let hasCreatedGroup = false;
                for (const answer of answersGroups) {
                    if (answer.some((item: any) => item.startTime === card.startTime)) {
                        answer.push(card);
                        hasCreatedGroup = true;
                        break;
                    }
                }
                if (!hasCreatedGroup) {
                    answersGroups.push([card]);
                }
            } else {
                answersGroups.push([card]);
            }
        }

        return (
            <Cards>
                {answersGroups.map((answers: any[], index: number) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                            background: '#f8f0e0',
                            borderRadius: '12px',
                            padding: '8px',
                            // ...(selectedAnswerIndex === '' + index && {
                            //   border: '6px solid #29C0E7',
                            // }),
                        }}>
                        <Box
                            sx={{
                                background: '#FCC86C',
                                color: colors.yellow900,
                                width: 'fit-content',
                                padding: '4px 32px',
                            }}>
                            {t('answerRound').replace('{{num}}', '' + (index + 1))}
                        </Box>
                        <CardAnswerGroupContainer>
                            {answers.map((answer: any, index) => {
                                const obj = JSON.parse(answer.data);
                                const card: any = Object.values(obj)[0];
                                return (
                                    <CardAnswerContainer>
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {t('answerTry', {
                                                num: (index + 1).toString(),
                                            })}
                                            :
                                        </Typography>
                                        <CardAnswer card={card} isCorrect={answer?.correct} locale={locale} />
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                            }}>
                                            {t('answerTime')}: {moment.utc(moment(answer?.endTime).diff(moment(answer?.startTime))).format('mm:ss')}
                                        </Typography>
                                    </CardAnswerContainer>
                                );
                            })}
                        </CardAnswerGroupContainer>
                    </Box>
                ))}
            </Cards>
        );
    };
    const [isAutoCorrect, SetisAutoCorrect] = useState(true);
    const { playingIdx, isPlaying, playSound } = useAudioResultAnswer({
        isPlaying: false,
        audio: null,
        playingIdx: -1,
    });
    useEffect(() => {
        if (data.questionType === 'Writing') {
            const item: any = Array.isArray(data.data) ? (data.data.length > 0 ? data.data[0] : {}) : {};
            if (Object.keys(item).length !== 0) {
                setSelectedAnswer([item]);
            }
            const obj = JSON.parse(item.data);
            const writing: any = Object.values(obj)[0];
            if (!writing.normalMode) {
                SetisAutoCorrect(false);
            }
        }
        if (data.questionType === 'Audio') {
            const item: any = Array.isArray(data.data) ? (data.data.length > 0 ? data.data[0] : {}) : {};
            if (Object.keys(item).length !== 0) {
                setSelectedAnswer([item]);
            }
            SetisAutoCorrect(false);
        }
        if (data.questionType === 'sasAudio') {
            const selectedcs3 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
            const item: any = Array.isArray(data.data) ? (data.data.length > 0 ? data.data[0] : {}) : {};
            if (Object.keys(item).length !== 0) {
                setSelectedAnswer([item]);
            }
            SetisAutoCorrect(false);
        }
        if (data.questionType === 'hkcsQ3') {
            const selectedcs3 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);

            const hkcsQ3Obj = JSON.parse(data.data[0].data);
        }
        if (data.questionType === 'sasFillInTheBlanks') {
            const selectedcs3 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
        }
        if (data.questionType === 'hkcsQ2') {
        }
        if (data.questionType === 'multipleChoice') {
            const selectedMc = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
            // console.log(selectedMc[0].content);
            const mCQuestionsObj: MCQuestionProp[] = (selectedMc[0].content?.questions as MCQuestionProp[]) ?? [];
            setMcQuestions(mCQuestionsObj);
        }
        if (data.questionType === 'hkcsQ1') {
            const selectedcs1 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
            const imagesArray: HkcsQ1QuestionImages[] = (selectedcs1[0].content?.questions as HkcsQ1QuestionImages[]) ?? [];
            setHkcsImage(imagesArray ?? []);

            // console.log(selectedMc[0].content);
            // const mCQuestionsObj: MCQuestionProp[] =
            //   selectedMc[0].content?.questions ?? [];
            // setMcQuestions(mCQuestionsObj);
        }
    }, [data]);
    return (
        <Container>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: colors.yellow900,
                    lineHeight: '150%',
                }}>
                {t('type')}: {gameTypeStr(data.questionType)}
            </Typography>
            {(data.questionType === 'Writing' || data.questionType === 'hkcsQ3' || data.questionType === 'hkcsEnQ2') && (
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: '14px',
                        color: '#0380F4',
                    }}>
                    {t('pleaseHoverToPlay')}
                </Typography>
            )}
            <AnswersContainer>
                {data.questionType === 'Writing' &&
                    data?.data?.map((item: any, index: number) => {
                        const obj = JSON.parse(item.data);
                        const writing: any = Object.values(obj)[0];
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                        lineHeight: '150%',
                                    }}>
                                    {t('answerTry', {
                                        num: (index + 1).toString(),
                                    })}
                                </Typography>
                                <Box
                                    onClick={() => {
                                        // setSelectedAnswerIndex('' + index);
                                        // setSelectedAnswer([item]);
                                    }}
                                    sx={{
                                        border: '1px solid #856666',
                                        borderRadius: '8px',
                                        // ...(selectedAnswerIndex === '' + index && {
                                        //   border: '6px solid #29C0E7',
                                        // }),
                                    }}>
                                    {writing.normalMode ? (
                                        <TopRightIconContainer>
                                            <EasyWritingAnswer strokes={writing.strokes} writingVersion={writing.version} />
                                            <TopRightIcon isCorrect={data.correct}>
                                                {data.correct ? (
                                                    <CheckRounded
                                                        sx={{
                                                            color: colors.reallyWhite,
                                                            display: 'block',
                                                            margin: 'auto',
                                                        }}
                                                    />
                                                ) : (
                                                    <ClearRounded
                                                        sx={{
                                                            color: colors.reallyWhite,
                                                            display: 'block',
                                                            margin: 'auto',
                                                        }}
                                                    />
                                                )}
                                            </TopRightIcon>
                                        </TopRightIconContainer>
                                    ) : (
                                        <WritingAnswer strokes={writing.strokes} writingVersion={writing.version} />
                                    )}
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                    }}>
                                    {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                </Typography>
                            </Box>
                        );
                    })}
                {data.questionType === 'hkcsQ3' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '72px',
                        }}>
                        {data?.data?.map((item: any, index: number) => {
                            let wordElement = '';
                            let vocab = '';
                            let imgInfo: HkcsQuestionImages = {
                                id: '',
                                image: '',
                                imageURL: '',
                                flip: -1,
                            };
                            const hkcsQ3ResultObj = JSON.parse(item.data);
                            if (data.questionType === 'hkcsQ3') {
                                const selectedcs3 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
                                let index: number = Number(selectedcs3[0].content?.character) ?? 0;
                                wordElement = selectedcs3[0].content?.phrase[index] ?? '';
                                vocab = selectedcs3[0].content?.phrase ?? '';
                                imgInfo = {
                                    id: '',
                                    image: selectedcs3[0].content?.image ?? '',
                                    imageURL: selectedcs3[0].content?.imageURL ?? '',
                                    flip: -1,
                                };
                            }
                            return (
                                <Box
                                    sx={{
                                        padding: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '16px',
                                            paddingLeft: '32px',
                                        }}>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {t('answerTry', {
                                                num: (index + 1).toString(),
                                            })}
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={item.files.length > 0 ? item.files[0].file.url : ''}
                                            alt='Example Image'
                                            sx={{
                                                width: 288, // width in pixels
                                                height: 177, // height in pixels
                                                flexShrink: 0,
                                                borderRadius: 12.5 / 2, // border radius in pixels
                                                border: '1.5px solid #000', // border style
                                                background: '#FFF',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {hkcsQ3ResultObj.hkcsQ3.textContent ?? ''}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '16px',
                                            }}>
                                            {hkcsQ3ResultObj.hkcsQ3.answer.map((item: any, index: number) => {
                                                const writing: any = item;
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '16px',
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid #856666',
                                                                borderRadius: '8px',
                                                                // ...(selectedAnswerIndex === '' + index && {
                                                                //   border: '6px solid #29C0E7',
                                                                // }),
                                                            }}>
                                                            {writing.normalMode ? (
                                                                <TopRightIconContainer>
                                                                    <EasyWritingAnswer strokes={writing.strokes} writingVersion={writing.version} />
                                                                    <TopRightIcon isCorrect={data.correct}>
                                                                        {data.correct ? (
                                                                            <CheckRounded
                                                                                sx={{
                                                                                    color: colors.reallyWhite,
                                                                                    display: 'block',
                                                                                    margin: 'auto',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <ClearRounded
                                                                                sx={{
                                                                                    color: colors.reallyWhite,
                                                                                    display: 'block',
                                                                                    margin: 'auto',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </TopRightIcon>
                                                                </TopRightIconContainer>
                                                            ) : (
                                                                <WritingAnswer strokes={writing.strokes} writingVersion={writing.version} />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>

                                        {/* <Dialog fullScreen open={true}>
                      <Box
                        sx={{
                          '@media print': {
                            width: '210mm',
                            height: '297mm',
                          },
                          border: 1,
                          width: '100%', // Add this
                          height: '100%', // Add this
                        }}></Box>
                      <Button
                        sx={{ position: 'absolute', top: 0 }}
                        variant='contained'
                        onClick={handlePrint}
                        className='no-print'>
                        Print
                      </Button>
                    </Dialog> */}
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                            }}>
                                            {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {data.questionType === 'hkcsQ2' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '72px',
                        }}>
                        {data?.data?.map((item: any, index: number) => {
                            const hkcsQ2ResultObj = JSON.parse(item.data);
                            return (
                                <Box
                                    sx={{
                                        padding: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '16px',
                                            paddingLeft: '32px',
                                            position: 'relative',
                                        }}>
                                        <FeedbackIcon isCorrect={true} top={-15} right={-45} />
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {t('answerTry', {
                                                num: (index + 1).toString(),
                                            })}
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={item.files.length > 0 ? item.files[0].file.url : ''}
                                            alt='Example Image'
                                            sx={{
                                                width: 288, // width in pixels
                                                height: 177, // height in pixels
                                                flexShrink: 0,
                                                borderRadius: 12.5 / 2, // border radius in pixels
                                                border: '1.5px solid #000', // border style
                                                background: '#FFF',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '16px',
                                            }}>
                                            <HKcsQ2Textbubbles sentence={hkcsQ2ResultObj.hkcsQ2.textContent} chosenWord={hkcsQ2ResultObj.hkcsQ2.answer} />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                            }}>
                                            {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {data.questionType === 'hkcsQ1' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '72px',
                        }}>
                        {data?.data?.map((item: any, index: number) => {
                            if (hkcsImage.length === 0) {
                                return <></>;
                            }
                            const selectIdx = JSON.parse(item.data).hkcsQ1.selectedIndex;
                            return (
                                <Box
                                    sx={{
                                        padding: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '16px',
                                            paddingLeft: '32px',
                                            position: 'relative',
                                        }}>
                                        <FeedbackIcon isCorrect={true} top={-15} right={-45} />

                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {t('answerTry', {
                                                num: (index + 1).toString(),
                                            })}
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={
                                                hkcsImage[selectIdx].imageURL && hkcsImage[selectIdx].imageURL !== ''
                                                    ? hkcsImage[selectIdx].imageURL
                                                    : `/characters/${hkcsImage[selectIdx].image !== '' ? hkcsImage[selectIdx].image + '/image.png' : 'default.png'}`
                                            }
                                            alt='bg'
                                            sx={{
                                                width: '240px', // width in pixels
                                                height: '160px', // height in pixels
                                                flexShrink: 0,
                                                // borderRadius: 12.5 / 2, // border radius in pixels
                                                // border: '1.5px solid #000', // border style
                                                background: '#FFF',
                                            }}
                                            // width={item.size.width}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                            }}>
                                            {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {data.questionType === 'hkcsEnQ2' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '72px',
                        }}>
                        {data?.data?.map((item: any, index: number) => {
                            let hkcsEnQ2ResultObj;
                            try {
                                hkcsEnQ2ResultObj = JSON.parse(item.data);
                            } catch (error) {
                                hkcsEnQ2ResultObj = {};
                            }
                            return (
                                <Box
                                    sx={{
                                        padding: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '16px',
                                            paddingLeft: '32px',
                                        }}>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {t('answerTry', {
                                                num: (index + 1).toString(),
                                            })}
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={item.files.length > 0 ? item.files[0].file.url : ''}
                                            alt='Example Image'
                                            sx={{
                                                width: 288, // width in pixels
                                                height: 177, // height in pixels
                                                flexShrink: 0,
                                                borderRadius: 12.5 / 2, // border radius in pixels
                                                border: '1.5px solid #000', // border style
                                                background: '#FFF',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {t('hkcs.en.q2.sampleSentence')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {hkcsEnQ2ResultObj?.hkcsEnQ2?.sampleSentence ?? ''}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '16px',
                                            }}>
                                            {Array.isArray(hkcsEnQ2ResultObj?.hkcsEnQ2?.sampleSentenceAnswer) &&
                                                hkcsEnQ2ResultObj?.hkcsEnQ2?.sampleSentenceAnswer.map((item: any, index: number) => {
                                                    const writing: any = item;
                                                    return (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '16px',
                                                            }}>
                                                            <Box
                                                                sx={{
                                                                    border: '1px solid #856666',
                                                                    borderRadius: '8px',
                                                                }}>
                                                                <EnglishWritingAnswer strokes={writing.strokes} writingVersion={writing.version} width={writing.width} />
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {hkcsEnQ2ResultObj?.hkcsEnQ2?.textContent ?? ''}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '16px',
                                            }}>
                                            {Array.isArray(hkcsEnQ2ResultObj?.hkcsEnQ2?.answer) &&
                                                hkcsEnQ2ResultObj?.hkcsEnQ2?.answer.map((item: any, index: number) => {
                                                    const writing: any = item;
                                                    return (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '16px',
                                                            }}>
                                                            <Box
                                                                sx={{
                                                                    border: '1px solid #856666',
                                                                    borderRadius: '8px',
                                                                }}>
                                                                <EnglishWritingAnswer strokes={writing.strokes} writingVersion={writing.version} width={writing.width} />
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                            }}>
                                            {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {data.questionType === 'hkcsEnQ1' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '72px',
                        }}>
                        {data?.data?.map((item: any, index: number) => {
                            let hkcsEnQ1ResultObj;
                            try {
                                hkcsEnQ1ResultObj = JSON.parse(item.data);
                            } catch (error) {
                                hkcsEnQ1ResultObj = {};
                            }
                            return (
                                <Box
                                    sx={{
                                        padding: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '16px',
                                            paddingLeft: '32px',
                                        }}>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {t('answerTry', {
                                                num: (index + 1).toString(),
                                            })}
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={item.files.length > 0 ? item.files[0].file.url : ''}
                                            alt='hkcsEnQ1 Student Image'
                                            sx={{
                                                width: 576, // width in pixels
                                                height: 576, // height in pixels
                                                flexShrink: 0,
                                                borderRadius: 12.5 / 2, // border radius in pixels
                                                border: '1.5px solid #000', // border style
                                                background: '#FFF',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '16px',
                                            }}>
                                            {Array.isArray(hkcsEnQ1ResultObj?.hkcsEnQ1?.answer) &&
                                                hkcsEnQ1ResultObj?.hkcsEnQ1?.answer.map((item: any, index: number) => {
                                                    const writing: any = item;
                                                    return (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '16px',
                                                            }}>
                                                            <Box
                                                                sx={{
                                                                    border: '1px solid #856666',
                                                                    borderRadius: '8px',
                                                                }}>
                                                                <EnglishWritingAnswer strokes={writing.strokes} writingVersion={writing.version} width={writing.width} />
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {hkcsEnQ1ResultObj?.hkcsEnQ1?.textContent ?? ''}
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                            }}>
                                            {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {data.questionType === 'multipleChoice' && (
                    <Box
                        sx={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '58px',
                        }}>
                        {data?.data?.map((item: any, index: number) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '18px',
                                    }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: colors.yellow900,
                                            lineHeight: '150%',
                                        }}>
                                        {t('answerTry', {
                                            num: (index + 1).toString(),
                                        })}
                                    </Typography>
                                    <MultipleChoiceAnswer mcQuestions={mcQuestios} data={item.data} />
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {data.questionType === 'Audio' &&
                    data?.data?.map((item: any, index: number) => {
                        const obj = JSON.parse(item.data);
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                        lineHeight: '150%',
                                    }}>
                                    {t('answerTry', {
                                        num: (index + 1).toString(),
                                    })}
                                </Typography>
                                <Box
                                    onClick={() => {
                                        // setSelectedAnswerIndex('' + index);
                                        // setSelectedAnswer([item]);
                                    }}
                                    sx={{
                                        border: '1px solid #856666',
                                        borderRadius: '8px',
                                        // ...(selectedAnswerIndex === '' + index && {
                                        //   border: '6px solid #29C0E7',
                                        // }),
                                    }}>
                                    <SoundBoxContainer>
                                        <AudioAnswer index={index} audioUrl={item.files[0].file.url} isPlaying={index == playingIdx && isPlaying} playSoundOnClick={playSound} />
                                    </SoundBoxContainer>
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                    }}>
                                    {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                </Typography>
                            </Box>
                        );
                    })}
                {data.questionType === 'sasAudio' &&
                    data?.data?.map((item: any, index: number) => {
                        const sasObj = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
                        let questionText = '';
                        if (sasObj.length > 0) {
                            if (sasObj[0].content) {
                                const sasContent: any = sasObj[0].content;
                                questionText = sasContent?.questionText.toString();
                            }
                        }
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                        lineHeight: '150%',
                                    }}>
                                    {t('answerTry', {
                                        num: (index + 1).toString(),
                                    })}
                                </Typography>
                                <Box
                                    onClick={() => {
                                        // setSelectedAnswerIndex('' + index);
                                        // setSelectedAnswer([item]);
                                    }}
                                    sx={{
                                        border: '1px solid #856666',
                                        borderRadius: '8px',
                                        // ...(selectedAnswerIndex === '' + index && {
                                        //   border: '6px solid #29C0E7',
                                        // }),
                                    }}>
                                    <SoundBoxContainer>
                                        <AudioAnswer index={index} audioUrl={item.files[0].file.url} isPlaying={index == playingIdx && isPlaying} playSoundOnClick={playSound} />
                                    </SoundBoxContainer>
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                    }}>
                                    {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                </Typography>
                            </Box>
                        );
                    })}
                {data.questionType === 'sasFillInTheBlanks' &&
                    data?.data?.map((item: any, index: number) => {
                        const sasFIBObj = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
                        let questionText = '';
                        if (sasFIBObj.length > 0) {
                            if (sasFIBObj[0].content) {
                                const sasContent: any = sasFIBObj[0].content;
                                questionText = sasContent.question;
                            }
                        }
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                        lineHeight: '150%',
                                    }}>
                                    {t('answerTry', {
                                        num: (index + 1).toString(),
                                    })}
                                </Typography>
                                <Box
                                    onClick={() => {
                                        // setSelectedAnswerIndex('' + index);
                                        // setSelectedAnswer([item]);
                                    }}
                                    sx={
                                        {
                                            // ...(selectedAnswerIndex === '' + index && {
                                            //   border: '6px solid #29C0E7',
                                            // }),
                                        }
                                    }>
                                    <HkSasFillInTheBlankAnswer question={questionText} data={item.data} startTime={item} endTime={item} />
                                </Box>
                                {/* <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: colors.yellow900,
                  }}>
                  {t('answerTime')}:{' '}
                  {moment
                    .utc(moment(item?.endTime).diff(moment(item?.startTime)))
                    .format('mm:ss')}
                </Typography> */}
                            </Box>
                        );
                    })}
                {data.questionType === 'sasFillInTheBlanksWriting' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '72px',
                        }}>
                        {data?.data?.map((item: any, index: number) => {
                            let questionText = '';
                            const sasReultObj = JSON.parse(item.data);
                            if (data.questionType === 'sasFillInTheBlanksWriting') {
                                const selectedcs3 = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
                                let questionObj: any = selectedcs3[0].content;
                                questionText = questionObj.question;
                            }
                            return (
                                <Box
                                    sx={{
                                        padding: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '16px',
                                            paddingLeft: '32px',
                                        }}>
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {t('answerTry', {
                                                num: (index + 1).toString(),
                                            })}
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                                lineHeight: '150%',
                                            }}>
                                            {questionText ?? ''}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '16px',
                                            }}>
                                            {sasReultObj.sasFillInTheBlanksWriting.answers.map((item: any, index: number) => {
                                                const writing: any = item;
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '16px',
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid #856666',
                                                                borderRadius: '8px',
                                                                // ...(selectedAnswerIndex === '' + index && {
                                                                //   border: '6px solid #29C0E7',
                                                                // }),
                                                            }}>
                                                            {writing.normalMode ? (
                                                                <TopRightIconContainer>
                                                                    <EasyWritingAnswer strokes={writing.strokes} writingVersion={writing.version} />
                                                                    <TopRightIcon isCorrect={data.correct}>
                                                                        {data.correct ? (
                                                                            <CheckRounded
                                                                                sx={{
                                                                                    color: colors.reallyWhite,
                                                                                    display: 'block',
                                                                                    margin: 'auto',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <ClearRounded
                                                                                sx={{
                                                                                    color: colors.reallyWhite,
                                                                                    display: 'block',
                                                                                    margin: 'auto',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </TopRightIcon>
                                                                </TopRightIconContainer>
                                                            ) : (
                                                                <WritingAnswer strokes={writing.strokes} writingVersion={writing.version} />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>

                                        {/* <Dialog fullScreen open={true}>
                      <Box
                        sx={{
                          '@media print': {
                            width: '210mm',
                            height: '297mm',
                          },
                          border: 1,
                          width: '100%', // Add this
                          height: '100%', // Add this
                        }}></Box>
                      <Button
                        sx={{ position: 'absolute', top: 0 }}
                        variant='contained'
                        onClick={handlePrint}
                        className='no-print'>
                        Print
                      </Button>
                    </Dialog> */}
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                color: colors.yellow900,
                                            }}>
                                            {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
                {data.questionType === 'sasRadicalMatching' &&
                    data?.data?.map((item: any, index: number) => {
                        const sasObj = selectedTestRoom.lessonVersion.lessonQuestions.filter((lessonObj) => lessonObj.id === data.questionID);
                        let sasRadicalMatchingResObj = JSON.parse(item.data);
                        let cardAns = [];
                        let questionImageURL1 = '';
                        let questionImageURL2 = '';
                        let sasRadicalCardContent: any = {};
                        let answersAttempted: number[] = JSON.parse(sasRadicalMatchingResObj?.sasRadicalMatching?.answers) as number[];
                        let isColumn: boolean = false;
                        if (sasObj.length > 0) {
                            if (sasObj[0].content) {
                                questionImageURL1 = sasObj[0].content.questionImageURL1 ?? '';
                                questionImageURL2 = sasObj[0].content.questionImageURL2 ?? '';
                                sasRadicalCardContent = sasObj[0].content;
                                cardAns = sasRadicalCardContent?.answers;
                                isColumn = sasRadicalCardContent?.layout === 1 ? false : true;
                            }
                        }
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                        lineHeight: '150%',
                                    }}>
                                    {t('answerTry', {
                                        num: (index + 1).toString(),
                                    })}
                                </Typography>
                                <Box
                                    onClick={() => {
                                        // setSelectedAnswerIndex('' + index);
                                        // setSelectedAnswer([item]);
                                    }}
                                    sx={
                                        {
                                            // ...(selectedAnswerIndex === '' + index && {
                                            //   border: '6px solid #29C0E7',
                                            // }),
                                        }
                                    }>
                                    <SasRadicalMatchingAnswer
                                        isColumn={isColumn}
                                        answers={answersAttempted}
                                        characterImg1={questionImageURL1}
                                        characterImg2={questionImageURL2}
                                        sasCardChoices={cardAns}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: colors.yellow900,
                                    }}>
                                    {t('answerTime')}: {moment.utc(moment(item?.endTime).diff(moment(item?.startTime))).format('mm:ss')}
                                </Typography>
                            </Box>
                        );
                    })}
                {(data.questionType === 'OneCard' || data.questionType === 'TwoCards') && renderCardAnswers(data)}
            </AnswersContainer>
            {!isAutoCorrect && (
                <ButtonsContainer>
                    <Button
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '8px 16px',
                            background: '#2E7D32',
                            borderRadius: '4px',
                            color: '#FFFFFF',
                            width: '132px',
                            fontWeight: '700',
                            fontSize: '15px',
                            lineHeight: '150%',
                            '&:hover': {
                                backgroundColor: '#00953A',
                            },
                            ...(grade === 'correct' && {
                                filter: 'drop-shadow(0px 0px 8px #00953A)',
                            }),
                        }}
                        disabled={selectedAnswer?.length === 0}
                        // startIcon={<Check />}
                        onClick={() => {
                            onGrading(selectedAnswer, 'correct');
                            setGrade('correct');
                        }}>
                        {t('correct')}
                    </Button>

                    <Button
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '8px 16px',
                            background: '#D32F2F',
                            borderRadius: '4px',
                            color: '#FFFFFF',
                            width: '132px',
                            fontWeight: '700',
                            fontSize: '15px',
                            lineHeight: '150%',
                            '&:hover': {
                                backgroundColor: '#F04438',
                            },
                            ...(grade === 'incorrect' && {
                                filter: 'drop-shadow(0px 0px 8px #F04438)',
                            }),
                        }}
                        disabled={selectedAnswer?.length === 0}
                        // startIcon={<Clear />}
                        onClick={() => {
                            onGrading(selectedAnswer, 'incorrect');
                            setGrade('incorrect');
                        }}>
                        {t('incorrect')}
                    </Button>
                </ButtonsContainer>
            )}
        </Container>
    );
};

const SoundBoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 184px;
    height: 184px;
    border-radius: 8px;
    background: #ffffff;
    position: relative;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    item-align: center;
    gap: 16px;
`;

const AnswersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
`;

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const CardAnswerGroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    background: #f8f0e0;
    border-radius: 12px;
`;

const CardAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 24px;
    background: ${colors.yellow050};
    border-radius: 12px;
`;

const TopRightIconContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const TopRightIcon = styled.div<TopRightIconProps>`
    display: flex;
    position: absolute;
    width: 32px;
    height: 32px;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    background: ${(props) => (props.isCorrect ? colors.success400 : colors.error500)};
`;
